export const SliderData = [
    {
        image:
          'pics/125.jpg'
      },
      {
        image:
          'pics/122.png'
      },
      {
        image:
          'pics/126.png'
      },
      {
        image:
          'pics/123.png'
      }
  ];